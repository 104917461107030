import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import componentStyles from "./services.module.scss"
import ServicesIllustration from "../assets/images/services-illustration.svg"
import Button from "../components/Button/Button"
import PresentationForm from "../components/PresentationForm/PresentationForm"

const Services: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const servicesClasses = classNames(componentStyles.services, "container")
  const servicesBlockClasses = classNames(
    componentStyles.servicesBlock,
    "grid-2"
  )
  const servicesBlockOneServiceClasses = classNames(
    componentStyles.servicesBlockOneService,
    "grid-2"
  )
  const servicesLastBlock = classNames(
    componentStyles.servicesLastBlock,
    "grid-2"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "services.seo.description",
        })}
        title={intl.formatMessage({ id: "services.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({ id: "services.title" })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              src={ServicesIllustration}
              alt="Illustration with people and dashboards"
              width="461"
              height="327"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({ id: "services.banner-text" })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={servicesClasses}>
        <div className={servicesBlockClasses}>
          <div className={servicesBlockOneServiceClasses}>
            <h4>{intl.formatMessage({ id: "services.development.title" })}</h4>
            <p>{intl.formatMessage({ id: "services.development.text" })}</p>
            <Button
              className={componentStyles.servicesBlockOneServiceButton}
              color="blue"
              to="/services/development"
            >
              {intl.formatMessage({ id: "services.button-title" })}
            </Button>
            <img
              className={componentStyles.servicesBlockOneServiceImage}
              src="/images/development.svg"
              alt="Illustration with people behind the table and dashboards"
              width="268"
              height="208"
            />
          </div>
          <div className={servicesBlockOneServiceClasses}>
            <h4>{intl.formatMessage({ id: "services.consulting.title" })}</h4>
            <p>{intl.formatMessage({ id: "services.consulting.text" })}</p>
            <Button
              className={componentStyles.servicesBlockOneServiceButton}
              color="blue"
              to="/services/consulting"
            >
              {intl.formatMessage({ id: "services.button-title" })}
            </Button>
            <img
              className={componentStyles.servicesBlockOneServiceImage}
              src="/images/consulting.svg"
              alt="Illustration with man standing in front of dashboards"
              width="268"
              height="208"
            />
          </div>
          <div className={servicesBlockOneServiceClasses}>
            <h4>{intl.formatMessage({ id: "services.data-science.title" })}</h4>
            <p>{intl.formatMessage({ id: "services.data-science.text" })}</p>
            <Button
              className={componentStyles.servicesBlockOneServiceButton}
              color="blue"
              to="/services/data-science"
            >
              {intl.formatMessage({ id: "services.button-title" })}
            </Button>
            <img
              className={componentStyles.servicesBlockOneServiceImage}
              src="/images/data-science.svg"
              alt="Illustration with dashboards, people and data blocks flowing"
              width="239"
              height="213"
            />
          </div>
          <div className={servicesBlockOneServiceClasses}>
            <h4>{intl.formatMessage({ id: "services.cloud.title" })}</h4>
            <div
              className={componentStyles.servicesBlockOneServiceCloudWrapper}
            >
              <p>{intl.formatMessage({ id: "services.cloud.text" })}</p>
              <div className={componentStyles.servicesBlockOneServiceWithLogos}>
                <img
                  src="/images/cloud-logo.svg"
                  alt="Google Cloud logo"
                  width="113"
                  height="21"
                />
                <img
                  src="/images/amazon.svg"
                  alt="Amazon Web Services logo"
                  width="36"
                  height="21"
                />
                <img
                  src="/images/microsoft.svg"
                  alt="Microsoft Azure logo"
                  width="106"
                  height="21"
                />
              </div>
            </div>
            <Button
              color="blue"
              className={componentStyles.servicesBlockOneServiceButton}
              to="/services/cloud"
            >
              {intl.formatMessage({ id: "services.button-title" })}
            </Button>
            <img
              className={componentStyles.servicesBlockOneServiceImage}
              src="/images/cloud.svg"
              alt="Illustration of cloud services and servers"
              width="256"
              height="208"
            />
          </div>
        </div>
        <div className={servicesLastBlock}>
          <div className={componentStyles.servicesLastBlockLeft}>
            <p>{intl.formatMessage({ id: "services.last-block.left" })}</p>
          </div>
          <div className={componentStyles.servicesLastBlockRight}>
            <p>
              <img
                src="/images/time-price.svg"
                alt="Illustration with clock and dollar sign"
                width="36"
                height="36"
              />
              {intl.formatMessage({
                id: "services.last-block.right-first",
              })}
            </p>
            <p>
              <img
                src="/images/fixed-price.svg"
                alt="Illustration with dollar sign inside square"
                width="36"
                height="36"
              />
              {intl.formatMessage({
                id: "services.last-block.right-second",
              })}
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Services
